import React, { useEffect } from "react"
import { applyTheme } from "../utils/themeSwitcher"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Link from "../components/Link"

const NotFoundPage = () => {
  const blk = {
    r: `20`,
    g: `20`,
    b: `20`,
  }

  useEffect(() => {
    applyTheme(blk)
  })

  return (
    <Layout>
      <SEO title="404: Not found" />
      <h1 className="serif f2">404</h1>
      <p className="sans f5">Nope, that is not a thing.</p>
      <p className="sans">
        <Link className={"no-underline h2 dib lh-copy"} to={"/"}>
          All articles
        </Link>
      </p>
    </Layout>
  )
}

export default NotFoundPage
